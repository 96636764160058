import { createRouter, createWebHistory } from 'vue-router';

import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import type { TelessaudeMods, TelessaudeServicesRoles } from '@/types';

import {
  isAuthenticated,
  getTokenMod,
  getTokenRoles,
  hasAuthorization as authHasAuthorization,
} from '@/utils/Auth/Auth';

// Modules routes
import HealthcareAssistance from '@md/HealthcareAssistance';
import RemoteCare from '@md/RemoteCare';

const { VITE_ENVIRONMENT, VITE_AUTH_WEB_URL } = import.meta.env;

const IS_PROD = VITE_ENVIRONMENT === 'PROD';

// Extends RouteMeta interface, to add custom properties
declare module 'vue-router' {
  interface RouteMeta {
    permissions?: {
      mods?: TelessaudeMods[],
      services?: Partial<TelessaudeServicesRoles>,
    },
  }
}

const HOME_ROUTE = {
  path: '/',
  name: 'home',
  // NOTE: there is no content for a "homepage"
  // component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
  redirect: { name: 'page-not-found' },
};

const NOT_FOUND_ROUTE = {
  // NOTE: this MUST be the last route
  path: '/:pathMatch(.*)*',
  name: 'page-not-found',
  component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound.vue'),
};

const PROD_ROUTES: RouteRecordRaw[] = [
  ...HealthcareAssistance.routes,
  ...RemoteCare.routes,
];

const DEV_ROUTES: RouteRecordRaw[] = [
  ...HealthcareAssistance.routes,
  ...RemoteCare.routes,
];

const getRoutes = () => [
  HOME_ROUTE,
  ...(IS_PROD ? PROD_ROUTES : DEV_ROUTES),
  NOT_FOUND_ROUTE,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: getRoutes(),
});

function redirectToAuth() {
  location.replace(`${VITE_AUTH_WEB_URL}/login`);
  return false;
}

function hasAuthorization(currentRoute: RouteLocationNormalized) {
  const modsFilter = currentRoute?.meta?.permissions?.mods || [];
  const servicesRolesFilter = currentRoute?.meta?.permissions?.services;
  const userMod = getTokenMod();

  if (!userMod) return false;

  const userSevicesRoles = getTokenRoles() || {};
  return authHasAuthorization(modsFilter, servicesRolesFilter, userMod, userSevicesRoles);
}

router.beforeEach((to) => {
  if (!isAuthenticated()) return redirectToAuth();
  if (!hasAuthorization(to)) {
    return {
      name: 'page-not-found',
      params: { pathMatch: to.path.substring(1).split('/') },
      replace: true,
    };
  }

  return true;
});

export default router;

<script lang="ts" setup>
import { ref, computed } from 'vue';

import { useTheme } from 'vuetify';
import { useAppStore } from '@/store/app';

// import AppNavbarProfileSelection from '@/components/AppNavbar/AppNavbarProfileSelection.vue';
import AppNavbarList from '@/components/AppNavbar/AppNavbarList.vue';
import { MenuItem, NavMenuItemsList } from '@/types';

const { VITE_AUTH_WEB_URL, VITE_PLATAFORMA_LEGACY_URL } = import.meta.env;

interface AppNavbarProps {
  color?: string,
  options?: object,
  density?: null | 'prominent' | 'default' | 'comfortable' | 'compact',
}

const LobbyNavListItem: MenuItem = {
  type: 'action',
  icon: 'mdi-account',
  label: 'Trocar perfil',
  value: () => {
    if (confirm('Você será direcionado à seleção de perfil, ok?'))
      location.href = `${VITE_AUTH_WEB_URL}/`;
  },
};

const LogoutNavListItem: MenuItem = {
  type: 'action',
  icon: 'mdi-logout',
  label: 'Sair',
  value: () => {
    if (confirm('Você será deslogado, ok?'))
      location.href = `${VITE_PLATAFORMA_LEGACY_URL}/accounts/logout/`;
  },
};

const RemoteCareNavListItems: MenuItem = {
  type: 'route',
  icon: 'mdi-account-injury-outline',
  label: 'Consultas remotas',
  value: '',
  subItems: [
    {
      type: 'route',
      label: 'TeleNutri',
      value: 'remote-care.telenutri',
      icon: '',
    }
  ],
};
const HealthcareAssistanceNavListItems: MenuItem = {
  type: 'route',
  icon: 'mdi-message-question-outline',
  label: 'Consultorias',
  value: '',
  subItems: [
    {
      type: 'route',
      label: 'Regulação ativa',
      value: 'healthcare-assistance.dynamic-prescription',
      icon: '',
    }
  ],
};

const theme = useTheme();
const appStore = useAppStore();

const props = withDefaults(defineProps<AppNavbarProps>(), {
  color: 'white',
  density: 'compact',
  options: () => ({}),
});

// Handle app bar customization properties
const appBarOptions = computed(() => ({
  color: props.color,
  density: props.density,
  ...props.options,
}));

// Handle user info
const userData = { name: appStore.getUserName };

// Handle user available navigation menu items
const hasRemoteCareAccess = computed(() => {
  if (!appStore.getUserServicesRoles) return false;
  const remoteCareRoles = appStore.getUserServicesRoles.remotecare;
  return !!remoteCareRoles.length;
});

const hasHealthcareAssistanceAccess = computed(() => {
  if (!appStore.getUserServicesRoles) return false;
  const roles0800 = appStore.getUserServicesRoles['0800'];
  return !!roles0800.includes('telerregulador');
});

const getAppNavbarListItems = (): NavMenuItemsList => {
  const arrListItems = [];
  arrListItems.push(LobbyNavListItem);
  if (hasRemoteCareAccess.value) arrListItems.push(RemoteCareNavListItems);
  if (hasHealthcareAssistanceAccess.value) arrListItems.push(HealthcareAssistanceNavListItems);
  arrListItems.push(LogoutNavListItem);
  return arrListItems;
}
const navlistItems = getAppNavbarListItems();

// Handle menu visibillity
const isShowingNavMenu = ref<boolean>(false);
const toggleNavVisibilitty = () => { isShowingNavMenu.value = !isShowingNavMenu.value };

// Toggle app theme
const toggleTheme = () => {
  appStore.setAppConfig({
    theme: theme.global.name.value !==  'telessaudeDefaultTheme'
      ? 'telessaudeDefaultTheme'
      : 'telessaudeDarkTheme'
  });
}
</script>

<template>
  <div class="app-navbar">
    <v-app-bar v-bind="appBarOptions" color="background" scroll-behavior="elevate" :scroll-threshold="1">
      <v-app-bar-nav-icon @click.stop="toggleNavVisibilitty"/>
      <v-img src="@/assets/logo_verde.png" alt="Plataforma Telessaúde" width="216px"/>
      <v-btn @click="toggleTheme" icon title="Alterar tema da aplicação">
        <v-icon icon="mdi-brightness-6" />
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="isShowingNavMenu"
      @click.self.prevent="toggleNavVisibilitty"
      width="420"
      temporary
      color="transparent"
      floating
      elevation="0">
      <div class="app-navbar__menu-wrapper rounded-be-lg">
        <!-- NOTE: It makes no sense to send user to the homepage if there is no content in it -->
        <!-- <router-link class="app-navbar__menu-header" :to="{ name: 'home' }"> -->
        <div class="app-navbar__menu-header">
          <!-- NOTE: We do not support avatar image at this moment -->
          <!-- <div class="app-navbar__user-avatar rounded-pill bg-white">
            <img class="app-navbar__user-avatar-image" :src="userData.avatar" />
          </div> -->
          <div class="app-navbar__user-name">{{ userData.name }}</div>
        <!-- </router-link> -->
        </div>
        <!-- NOTE: Removed profile selection for now, since it does not seem to make sense in the current aplication -->
        <!-- <app-navbar-profile-selection /> -->
        <app-navbar-list class="pt-0 pb-5" :navlist-items="navlistItems" />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
.app-navbar {
  :deep() {
    .v-app-bar.v-toolbar.v-theme--telessaudeDefaultTheme:not(.v-toolbar--flat) {
      box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.15);
    }
    .v-navigation-drawer__content { height: auto; }
  }
  .app-navbar__menu-wrapper {
    background-color: rgba(var(--v-theme-background));
    .app-navbar__menu-header {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.175rem 1.5rem;
      background-color: rgba(var(--v-theme-primary));
      color: rgba(var(--v-theme-on-primary));
      text-decoration: none;
      .app-navbar__user-avatar {
        width: 30px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .app-navbar__user-avatar-image {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .app-navbar__user-name {
        width: calc(100% - 30px);
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
}
</style>
